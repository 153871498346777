<template>
	<v-main class="bg-grey-lighten-3">			
		<v-row>
			<v-col>
				<v-sheet
					min-height="70vh"
					rounded="lg"
				>
					<v-col
						cols="12"
						v-if="libroSel != null ? true : false"
					>
						<!-- <div class="row">
							<v-btn
								color="info"
								class="col-auto"
								v-on:click="() => { $parent.visDettaglio = false; }"
							>
								&lt;
							</v-btn>
						</div> -->

						<v-row >
							<v-col
								cols="4"
							>
								<v-row min-height="10vh">
									<h2 ref="bottom" ></h2>
								</v-row>
								<v-row min-height="40px" v-for="p in libroSel.proprietalibris" :key="p.Idproprieta">
									<div><b>{{ p.descrizioneProp }}</b> : 
										<a v-if="p.valoreProprieta.includes('www') || p.valoreProprieta.includes('http')"
											:href="p.valoreProprieta" 
											:target="!p.valoreProprieta.includes('www') || p.valoreProprieta.includes('http') ? '' : '_blank'"
										>
											{{ p.valoreProprieta }}
										</a>
											{{ !p.valoreProprieta.includes('www') && !p.valoreProprieta.includes('http') ? p.valoreProprieta : '' }}
									</div>
								</v-row>
							</v-col>
							<!-- <v-col cols="8">
								<img :src="$pathLibri + libroSel.percorsoImmaginePrincipale" />
								<v-row v-for="(p,index) in libroSel.percorsiFiles" :key="index">
									{{ p.split('\\').pop() }}
								</v-row>
							</v-col> -->
						</v-row>
						<v-row v-show="libroSel.percorsiFilesPDF.length > 0" :style="windowWidth > 1300 ? 'margin-top: -40pt;' : '' ">
							<h2>Link PDF</h2>
							<v-col cols="12">
								<v-table>
									<thead>
										<!-- <th style="width: 10em;">Immagine</th> -->
										<th style="width: 20em;"></th>
									</thead>
									<tbody>
										<tr
											v-for="imm in libroSel.percorsiFilesPDF.slice(paginaPdf*lunghezzaLista, (paginaPdf*lunghezzaLista) + lunghezzaLista)" :key="imm"
										>
											<!-- <td style="text-align: center;">--</td> -->
											<td style="text-align: center;"><a :href="imm" target="_blank">{{ imm.split('/').pop() }}</a></td>
										</tr>
									</tbody>
								</v-table>
								<br />
								<div v-if="parseInt(libroSel.percorsiFilesPDF.length / lunghezzaLista) > 0">
									<v-btn
										color="info"
										v-on:click="() => { if(paginaPdf > 0) {paginaPdf--} }"
									>
										&lt;
									</v-btn>
									<v-btn
										color="info"
										v-on:click="() => { if((paginaPdf + 1) * lunghezzaLista < libroSel.percorsiFilesPDF.length) {paginaPdf++} }"
									>
										&gt;
									</v-btn>
									{{ paginaPdf }} di {{ parseInt(libroSel.percorsiFilesPDF.length / lunghezzaLista) }}
								</div>
							</v-col>
							<!-- <v-col cols="12">
								<v-row style="padding-top: 10px" v-for="pdf in libroSel.percorsiFilesPDF" :key="pdf">
									<a :href="pdf">{{ pdf.split('\\').pop() }}</a><br />
								</v-row>
							</v-col> -->
							<br />
							<br />
						</v-row>
						<v-row v-show="libroSel.percorsiFilesIMG.length > 0">
							<h2>Immagini</h2>
							<br />
							<br />
							<v-col cols="12">
								<v-row>
								<!-- <div class="row"
										style="align-items: center; justify-content: center;"
									> -->
									<v-col v-for="(imm, index) in libroSel.percorsiFilesIMG.slice(paginaImg*lunghezzaLista, (paginaImg*lunghezzaLista) + lunghezzaLista)" :key="index" class="d-flex child-flex" cols="1.2">
										<!-- <div class="col-auto" v-for="(imm, index) in libroSel.percorsiFilesIMG.slice(paginaImg*lunghezzaLista, (paginaImg*lunghezzaLista) + lunghezzaLista)" :key="index"> -->
											<v-dialog
													transition="dialog-top-transition"
													max-width="500"
												>
												<template v-slot:activator="{ on, attrs }">
													<img 
														:src="imm.replace('.jpg' || '.png', imm.includes('.jpg') ? '_thumbnail.jpg' : '_thumbnail.png')" 
														v-bind="attrs"
														v-on="on"
														:style="'width: '+ parseInt(windowWidth/10) +'px;'"
														v-on:click="() => {immagineAperta = imm; indiceImmGrande = index + (paginaImg*lunghezzaLista); }"
													/>
												</template>
												<template v-slot:default="dialog">
													<v-card>
														<v-toolbar
															class="d-flex "
															color="primary"
															dark
														>
															<v-col cols="10"></v-col>
															<v-icon v-on:click="cambiaImmagine(indiceImmGrande - 1)"
															>mdi-menu-left</v-icon>
															{{ immagineAperta.split('/').pop().replace('.jpg' || '.png', '') }}
															<v-icon v-on:click="cambiaImmagine(indiceImmGrande + 1)"
															>mdi-menu-right</v-icon>
															<v-col cols="10"></v-col>
															<v-card-actions>
																<v-icon
																	text
																	@click="dialog.value = false"
																>mdi-close-circle</v-icon>
															</v-card-actions>
														</v-toolbar>
														<v-img
															max-height="9%"
															:src="immagineAperta"
														></v-img>
														
													</v-card>
												</template>
											</v-dialog>
										<!-- </div> -->
										</v-col>
									</v-row>
										<!-- </div> -->
								<br />
								<div v-if="parseInt(libroSel.percorsiFilesIMG.length / lunghezzaLista) > 0">
									<v-btn
												color="info"
												v-on:click="() => { if(paginaImg > 0) {paginaImg--} }"
											>
												&lt;
									</v-btn>
									<v-btn
												color="info"
												v-on:click="() => { if((paginaImg + 1) * lunghezzaLista < libroSel.percorsiFilesIMG.length) {paginaImg++} }"
											>
												&gt;
									</v-btn>
									{{ paginaImg }} di {{ parseInt(libroSel.percorsiFilesIMG.length / lunghezzaLista) }}
								</div>
								<!-- <v-row style="padding-top: 10px">
									<br />
								</v-row> -->
							</v-col>
							<br />
							<br />
						</v-row>
						<v-row v-show="libroSel.percorsiFilesALTRO.length > 0">
							<h2>Altro</h2>
							<br />
							<br />
							<v-col cols="12">
								<v-table>
									<thead>
										<th style="width: 10em;">Immagine</th>
										<th style="width: 20em;">Percorso</th>
									</thead>
									<tbody>
										<tr
											v-for="imm in libroSel.percorsiFilesALTRO.slice(paginaAltro*lunghezzaLista, (paginaAltro*lunghezzaLista) + lunghezzaLista)" :key="imm"
										>
											<td style="text-align: center;">--</td>
											<td style="text-align: center;"><a :href="imm" target="_blank">{{ imm.split('/').pop() }}</a></td>
										</tr>
									</tbody>
								</v-table>
								<br />
								<div v-if="parseInt(libroSel.percorsiFilesALTRO.length / lunghezzaLista) > 0">
									<v-btn
												color="info"
												v-on:click="() => { if(paginaAltro > 0) {paginaAltro--} }"
											>
												&lt;
									</v-btn>
									<v-btn
												color="info"
												v-on:click="() => { if((paginaAltro + 1) * lunghezzaLista < libroSel.percorsiFilesALTRO.length) {paginaAltro++} }"
											>
												&gt;
									</v-btn>
									{{ paginaAltro }} di {{ parseInt(libroSel.percorsiFilesALTRO.length / lunghezzaLista) }}
								</div>
							</v-col>
							<!-- <v-col cols="12">
								<v-row style="padding-top: 10px" v-for="other in libroSel.percorsiFilesALTRO" :key="other">
									<a :href="other">{{ other.split('\\').pop() }}</a><br />
								</v-row>
							</v-col> -->
							<br />
							<br />
						</v-row>
					</v-col>
				</v-sheet>
			</v-col>
		</v-row>

	</v-main>
</template>

<script>
import axios from 'axios';
export default {
	data: () => ({
		idLibro: null,
		libroSel: {},
		lunghezzaLista: 40,
		numeroRigaTabellaImm: [1,2,3,4,5],
		paginaImg: 0,
		paginaPdf: 0,
		paginaAltro: 0,
		immagineAperta: "",
		indiceImmGrande: -1,
		primo: true,
		windowWidth: window.innerHeight,
	}),

	methods: {
		cambiaImmagine(id){
			if(id > -1){
				this.indiceImmGrande = id;
				this.immagineAperta = this.libroSel.percorsiFilesIMG[id];
			}
		},
		caricaLibro(){
			var pass = this;
			axios
				.get(this.$hostName + "Ricerca/DettLibro", { params: {idLibro: this.idLibro }})
				.then((response) => {
					pass.libroSel = response.data;
					setTimeout(() => {pass.$refs["bottom"].scrollIntoView({ behavior: "smooth" });},0);
				});
		},
		caricaIdLibro(id){
			this.idLibro = id;
			this.paginaImg = 0;
			this.paginaPdf = 0;
			this.paginaAltro = 0;
			this.caricaLibro();
		},
		onResize() {
      this.windowWidth = window.innerWidth;
    },
	},
	created(){
		
	},
	mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
	beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
}
</script>

<!-- <script setup>


	const config = useRuntimeConfig();
	const hostName = config.public.apiBase;
	const route = useRoute();
	const idLibro = route.params.id;
	const libroSel = await fetch(hostName + "Ricerca/DettLibro?" + new URLSearchParams({  idLibro: idLibro })).then((r) => r.json());
</script> -->