import Vue from "vue";
import Router from "vue-router";
import TestHome from "./components/TestHome.vue";

Vue.use(Router);
export default new Router({
  routes: [
    {
			path: "/",
			name: "home",
			component: TestHome,
    },
	],
});
