<template>
	<div v-if="ListMenu">
		<v-menu
			top
			offset-x
			v-for="c in ListMenu" :key="c.id"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="ma-1"
					color="grey"
					plain
					v-bind="attrs"
					v-on="on"
					v-on:click="c.categorieFiglie ? null : caricaLibriCategoria(c.id)"
				>
					{{ c.descrizione }}
				</v-btn>
			</template>
			<v-list offset-x v-if="c.categorieFiglie">
				<v-list-item
					v-for="(ci, index) in listMenuFigli()"
					:key="index"
					v-on:click="ci.categorieFiglie ? null : caricaLibriCategoria(ci.id)"
				>
					<!-- <v-list-item-title v-if="!ci.categorieFiglie">{{ ci.descrizione }}</v-list-item-title> -->
					<componente-menu v-if="ci.categorieFiglie" ref="componenteMenu" :ListMenu="ci.categorieFiglie" />
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import axios from 'axios';
import ElementoMenu from './elementoMenu.vue';

export default {
  name: "componenteMenu",
  components:{
		componenteMenu: ElementoMenu,
  },
	props: {
		ListMenu: Array,
	},
  data() {
    return {
      returnTest: "",
      strRicerca: "",
      listLibri: [],
      listTestataTab: [],
      headers: [],
      listLibriTable: [],
      visTabella: false,
      visDettaglio: false,
      categorieMenu: null,
    };
  },
  methods: {
		caricaLibriCategoria(id){
      var pass = this;
      this.listLibri = [];
      axios
        .get(this.$hostName + "Ricerca/Ricerca", {params: { ricerca: null, idCategoria: id }})
        .then((response) => {
          pass.listLibri = response.data;
          pass.visTabella = true;
          pass.creaListaTabella();
        });
    },
		listMenuFigli(){
			var ret = [];
			this.ListMenu.forEach((e) => {
				if (e.categorieFiglie){
					ret.push(e);
				}
			});
			return ret;
		},
		listMenuNoFigli(){
			var ret = [];
			this.ListMenu.forEach((e) => {
				if (!e.categorieFiglie){
					ret.push(e);
				}
			});
			return ret;
		},
  },
  mounted: function () {

  },

  created() {

  },
};
</script>
