import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import router from "./router";
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import $ from "jquery";
// import cors from 'vue-cli-plugin-cors';
//var $ = require( "jquery" );

import moment from "moment";

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.config.productionTip = false;

//Vue.prototype.$hostName = "http://localhost:5000/api/";
// Vue.prototype.$hostName = "http://10.138.134.108:5000/api/";
Vue.prototype.$hostName = "https://libriantichi.unitus.it:5000/api/";
Vue.prototype.$pathLibri = "https://libriantichi.unitus.it/libri/";
//Vue.prototype.$pathLibri = "http://localhost:80/libri/";
// Vue.prototype.$hostName = "http://localhost:8080/api/";

// Vue.prototype.$hostName = process.env.NODE_ENV === 'production'  ? '/api/' : 'http://localhost:5000/api/';

Vue.use(VueRouter);
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use($);
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.withCredentials = false;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import vuetify from './plugins/vuetify'