<template>
  <div
    class="wrapper wrapper-content animated fadeInRight"
    style="min-height: 100%"
  >
    <div class="row">
      <!-- <v-btn
        class="ma-1"
        color="grey"
        plain
        v-on:click="caricaTutto()"
      >
        VISUALIZZA TUTTI
      </v-btn> -->
  
      <elemento-menu ref="ElementoMenu" :ListMenu="categorieMenu" />
    </div>
    <div class="text-center m-t-lg" style="margin-top: 3%;">
      <img
        src="../assets/logoUniBlu.png"
        height="125"
        
      />
    </div>
    <div >
      <form class="row g-3 d-flex justify-content-center">
  
        <div class="col-8">
          <label for="inputPassword2" class="visually-hidden">Ricerca</label>
          <input type="text" class="form-control" placeholder="Ricerca..." v-model="strRicerca" v-on:keyup="chkRicerca">
        </div>
  
      </form>
      <br />
      <br />
      <button
        type="button"
        class="btn btn-primary col-2"
        style="margin-right: 3%"
        v-on:click="ricerca()"
      >Ricerca</button>
      <button
        type="button"
        class="btn btn-primary col-2"
        style="margin-left: 3%"
        v-on:click="caricaTutto()"
      >visualizza intera collezione</button>
    </div>
    <div class="row" >
      <div class="col-12" v-show="visTabella">
        <v-data-table
          :headers="headers"
          :items="listLibriTable"
          :items-per-page="15"
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr @click="rowClicked(item)" style="text-align: left; cursor: pointer;">
              <td v-for="(it,index) in item" :key="index">{{ index == 'id' ? '' : it }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <DettLibro ref="DettLibro" v-show="visDettaglio"/>
      </div>
    </div>
	</div>
</template>

<script>
import axios from 'axios';
import DettLibro from './detailsLibro.vue';
import ElementoMenu from './elementoMenu.vue';

export default {
  name: "TestHome",
  components:{
    DettLibro,
    ElementoMenu,
  },
  data() {
    return {
      returnTest: "",
      strRicerca: "",
      listLibri: [],
      listTestataTab: [],
      headers: [],
      listLibriTable: [],
      visTabella: false,
      visDettaglio: false,
      categorieMenu: null,
    };
  },
  methods: {
    chkRicerca(e){
      if (e.keyCode === 13) {
        this.ricerca();
      }
    },
    rowClicked(item){
      this.visDettaglio = true;
      this.$refs.DettLibro.caricaIdLibro(item.id);
      
    },
    caricaListaCategorie(){
      var pass = this;
      axios
        .get(this.$hostName + "Categorie/ListCategorieMenu")
        .then((response) => {
          pass.categorieMenu = response.data;
        });
    },
    chiamaTest(){
      var pass = this;
      axios.get(this.$hostName + "Common/test")
      .then((response) => {
        pass.returnTest = response.data;
      });
    },
    ricerca(id){
      if (this.strRicerca){
        this.listLibri = [];
        this.visDettaglio = false;
        var pass = this;
        axios
          .get(this.$hostName + "Ricerca/Ricerca", {params: { ricerca: this.strRicerca, idCategoria: id }})
          .then((response) => {
            pass.listLibri = response.data;
            pass.visTabella = true;
            pass.creaListaTabella();
          });
      }else{
        alert("Inserire almeno un termine di ricerca.");
      }
    },
    caricaTutto(){
      var pass = this;
      this.listLibri = [];
      axios
        .get(this.$hostName + "Ricerca/CaricaTutto")
        .then((response) => {
          pass.listLibri = response.data;
          pass.visTabella = true;
          pass.creaListaTabella();
        });
    },
    creaListaTabella(){
      this.listLibriTable = [];
      for(var e = 0; e < this.listLibri.length; e++){
        for (var q = 0; q < this.listLibri[e].proprietalibris.length; q++){

          for(var z = 0; z < this.listTestataTab.length; z++){
            if (this.listLibri[e].proprietalibris[q].idproprieta == this.listTestataTab[z].idanagrafica){
              var indiceTabella = -1;
              this.listLibriTable.forEach((elem,index) => {
                if(elem.id == this.listLibri[e].id)
                  indiceTabella = index;
              });
              if (indiceTabella > -1){
                Object.assign(this.listLibriTable[indiceTabella], { id: this.listLibri[e].id, [this.listTestataTab[z].descrizione] : this.listLibri[e].proprietalibris[q].valoreProprieta })
              }else{
                var lTable = {id: this.listLibri[e].id, [this.listTestataTab[z].descrizione] : this.listLibri[e].proprietalibris[q].valoreProprieta};
                //lTable.[this.listLibri[e].proprietalibris[q].descrizione] = this.listLibri[e].proprietalibris[q].ValoreProprieta;
                this.listLibriTable.push(lTable);
              }
            }
          }
        }
      }
    },
    caricaListTestata(){
      var pass = this;
      var id = {};
      id.text = "id";
      id.value = "id";
      pass.headers.push(id);
      axios
        .get(this.$hostName + "Proprieta/ListProprietaTestata")
        .then((response) => {
          pass.listTestataTab = response.data;
          pass.listTestataTab.forEach((e) => {
            var h = {};
            h.text = e.descrizione;
            h.value = e.descrizione;
            pass.headers.push(h);
          });
          pass.headers.forEach((e) => {if (e.text == 'id') e.text = '';});
        });
    },
    ricercaProprieta(testa, propLibro){
      var ret = "";
      propLibro.forEach((e) => {
        if(testa.idanagrafica == e.idproprieta)
          ret = e.valoreProprieta;
      });
      return ret;
    },
  },
  mounted: function () {
    this.chiamaTest();
    this.caricaListTestata();
    this.caricaListaCategorie();
  },

  created() {

  },
};
</script>
