<template>
  <v-app>
    <!--<v-app-bar style="max-height: 30pt;">
       <v-container class="fill-height d-flex align-center"> -->
        
        <!-- <v-app-bar-nav-icon
          class="mr-10 ml-4"
          color="blue"
          size="32"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon> --
        <a href="/"><v-icon icon="fa-home" style="color:rgb(33,150,243); cursor: pointer; "/></a>
        !-- <v-avatar
          class="mr-10 ml-4"
          color="grey-darken-1"
          size="32"
        ></v-avatar> 
        

        <v-spacer></v-spacer>

        <v-responsive max-width="260">
          <v-text-field
            density="compact"
            hide-details
            variant="solo"
          ></v-text-field>
        </v-responsive> -->
      <!-- </v-container> 
    </v-app-bar> -->
    <!-- <v-spacer></v-spacer> -->
    
    <br />
    <v-main>
      <div class="text-center m-t-lg">
        <router-view />
      </div>
    </v-main>
    <v-footer class="d-flex flex-column">
      <div class="px-4 py-2 text-center w-100">
        {{ new Date().getFullYear() }} — <strong>Powered by <a href="http://www.virtualup.org/" target="_blank">VirtualUP</a></strong>
      </div>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      
    };
  },
  methods : {
    
  },
  mounted(){
    
  },
};
</script>
